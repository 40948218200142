import { FC, useEffect, useCallback, useState } from "react";

import styles from "./Post.module.css";

import Image from "../image/Image";
import { useConfigBase } from "@/contexts/configBase.context";
import u from "@/util";

interface IPostItem {
  title: string;
  thumb: string;
  name: string;
  count: number;
  channel: string;
  tags: string;
  update_time: number;
  is_free: number;
}

interface IPost {
  post: IPostItem;
  onClick: (post: any) => void;
}

const Post: FC<IPost> = ({ post, onClick }) => {
  const { configBaseList } = useConfigBase();
  const [encryptUrls, setEncryptUrls] = useState("");
  const handlerOnClick = () => {
    onClick(post);
  };

  const fmtimg = useCallback(async () => {
    const imageUrlKey = configBaseList.mm_pic_image_domain || "";

    const urls = `${imageUrlKey}/${post.thumb}.txt`;
    setEncryptUrls(urls);
  }, [configBaseList.image_url, encryptUrls, post.thumb]);

  useEffect(() => {
    fmtimg();
  }, [post.thumb]);

  return (
    <div className={styles.postCard} onClick={handlerOnClick}>
      <div className={post.channel === "meinv" && post.tags === undefined ? styles.meinvPostProfile : styles.postProfile}>
        <Image srcValue={post && post.thumb} className={styles.iCvr} size={true}/>
        <Image srcValue={post && post.thumb} className={styles.postImage} size={true}/>
      </div>
      <div className={styles.postDesc}>
        <p className={styles.postDescTitle}>
          {post.title && post.title.length > 18
            ? `${post.title.slice(0, 18)}...`
            : post.title || post.name}
        </p>
        <div className={styles.postViews}>
          <div className={styles.postViewLabelList}>
            <div className={styles.postViewLabel}>
              <p>
                {post.count}
                {post && post.channel === "meinv" ? "期" : "P"}
              </p>
            </div>
            {post?.is_free === 2 && (
              <div className={styles.postViewLabelPurple}>
                <p>限时免费</p>
              </div>
            )}
          </div>
          <div className={styles.postViewDate}>
            {/* <p> {u.toFmt(post.update_time * 1000) || "-"}</p> */}
            <p> {u.fmtDateSince(post.update_time * 1000) || "-"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Post;
