import { PROD_SITE } from "@/const/prod.const";
import useRequest from "@/hooks/useRequest";
import u, { ELocalKey } from "@/util";
import React, { useState, useEffect, useContext, createContext } from "react";

interface ReactNodeProps {
  children: React.ReactNode;
}

export interface IConfigBase {
  image_url?: string;
  mm_pic_image_domain?: string;
  encrypted_image_domain?: string;
  yimi_statistic_domain?: string;
}

export type ConfigBaseContextType = {
  configBaseList: IConfigBase;
};

export const ConfigBaseContext = createContext<ConfigBaseContextType | null>({
  configBaseList: {
    image_url: "",
    mm_pic_image_domain: "",
    encrypted_image_domain: "",
    yimi_statistic_domain: "",
  },
});

export const ConfigBaseProvider: React.FC<ReactNodeProps> = ({ children }) => {
  const [configBaseList, setConfigBaseList] = useState<IConfigBase>({
    image_url: "",
  });

  const { makeRquest: requestConfigBase } = useRequest("config/base", "post");

  useEffect(() => {
    const fetchConfigBase = async () => {
      try {
        const { data } = await requestConfigBase({ site: PROD_SITE });

        if (data) {
          setConfigBaseList(data);
          localStorage.setItem(
            ELocalKey._BASE,
            u.encrypt(JSON.stringify(data))
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchConfigBase();
  }, []);

  useEffect(() => {
    if (configBaseList && configBaseList?.yimi_statistic_domain) {
      u.documentWriteStatisticsScript(configBaseList?.yimi_statistic_domain);
      // documentInsertStatisticsScript(configBaseList?.yimi_statistic_domain);
    }
  }, [configBaseList]);

  const value = { configBaseList };
  return (
    <ConfigBaseContext.Provider value={value}>
      {children}
    </ConfigBaseContext.Provider>
  );
};

export const useConfigBase = () => {
  return useContext(ConfigBaseContext) as ConfigBaseContextType;
};
