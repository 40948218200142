import { useConfigBase } from "@/contexts/configBase.context";
import { FC, useCallback, useEffect, useState } from "react";

// import defaultImg from "../../assets/750x242.jpg";
import u from "@/util";

interface IImage {
  srcValue: string;
  className?: string;
  onClick?: (value: any) => void;
  size?: boolean;
}

const Image: FC<IImage> = ({
  srcValue = "",
  className = "",
  onClick = () => {},
  size = false,
}) => {
  const { configBaseList } = useConfigBase();
  const [imgSrc, setImgSrc] = useState(`${process.env.REACT_APP_LOCAL_IMAGE}/images/750x242.jpg`);

  const handlerOnClick = (value: any) => {
    onClick(value);
  };

  const fmtimg = useCallback(async () => {
    const imageUrlKey = configBaseList.mm_pic_image_domain || "";
    // const imageUrlKey = configBaseList.encrypted_image_domain || "";

    if (
      srcValue &&
      (srcValue.includes("https://") || srcValue.includes("http://"))
    ) {
      return setImgSrc(srcValue);
    }
    if (imageUrlKey !== "" && srcValue !== "") {
      // const vidKeyParam = u.addImgKeyParam(srcValue);

      // const dynamicEncryptUrl = `${imageUrlKey}${srcValue}${vidKeyParam}`;
      // console.log('dynamicEncryptUrl', dynamicEncryptUrl)
      // return setImgSrc(dynamicEncryptUrl);

      let encryptUrls = `${imageUrlKey}/${srcValue}.txt`;

      if (size) {
        encryptUrls = `${encryptUrls}?size=600x337`;
      }

      const res = await u.fetchData(encryptUrls);

      let __decrypted = "";
      if (res) {
        __decrypted = res.indexOf("data") >= 0 ? res : u.imgDecrypt(res);
        return setImgSrc(__decrypted);
      }
    }
  }, [configBaseList.image_url, srcValue]);

  useEffect(() => {
    // setImgSrc(defaultImg);
    fmtimg();
  }, [fmtimg, srcValue]);

  return (
    <img
      src={imgSrc}
      alt={className}
      className={className}
      onClick={handlerOnClick}
    />
  );
};

export default Image;
